.container {
  width: 98%;
  margin: auto;
}
.my-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 1rem;
  border-bottom: 1px solid #1a1a1a;
  text-shadow: 3px 3px #1a1a1a;
}
.row {
  width: 100%;
  margin: 27.5px 0;
}
.input-group {
  width: 80%;
  height: 30px;
  margin: auto;
}
.form-control {
  display: inline-block;
  width: 70%;
  height: 100%;
  text-indent: 10px;
  font-family: inherit;
  font-size: 1rem;
  background-color: #1a1a1a;
  color: #ff6600;
  border: none;
}
.form-control:focus {
  outline: none;
}
.input-group-append {
  display: inline-block;
  width: 30%;
  height: 100%;
}
.btn {
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  color: white;
  border-color: #888;
  font-family: inherit;
  cursor: pointer;
}
.todo-holder {
  width: 100%;
}
.card {
  width: 280px;
  height: 150px;
  margin: 20px auto;
  border: 1px solid #1a1a1a;
  text-align: center;
}
.card-header,
.card-body,
.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}
.card-header {
  color: #888;
}
.card-body {
  align-items: baseline;
  background-color: #1a1a1a;
  height: 50px;
  overflow: auto;
}
.card-body > h3 {
  padding-top: 10px;
}
.card-body > h3::first-letter {
  text-transform: capitalize;
}
.card-footer {
  justify-content: space-around;
}
.delete-btn,
.mark-btn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border-color: #888;
  cursor: pointer;
  background-color: #1a1a1a;
  color: #888;
  font-family: inherit;
}
.complete {
  text-decoration: line-through;
  color: #888;
}
.incomplete {
  text-decoration: none;
}
.my-footer {
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #1a1a1a;
}

@media all and (min-width: 576px) {
  .input-group {
    width: 70%;
  }
  .todo-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .card {
    margin: auto;
    margin-bottom: 20px;
  }
}
@media all and (min-width: 768px) {
  .container {
    width: 80%;
  }
  .input-group {
    width: 60%;
  }
}
@media all and (min-width: 992px) {
  .container {
    width: 90%;
  }
}
@media all and (min-width: 1200px) {
  .container {
    width: 75%;
  }
  .my-header {
    font-size: 1.2rem;
  }
  .input-group {
    width: 50%;
  }
}
@media all and (min-width: 1400px) {
  .container {
    width: 85%;
  }
  .my-header {
    font-size: 1.5rem;
  }
  .input-group {
    width: 40%;
  }
  .my-footer {
    padding: 30px 0;
    font-size: 1.1rem;
  }
}
